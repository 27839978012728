/* eslint-disable */
"use strict";
$(function () {
    $(document).on('click', '.dumb-tabs__button', function (e) {
        e.preventDefault();
        const use_button = $(this);
        if (use_button.hasClass('dumb-tabs__button_active')) {
            return false;
        }
        const active_button = use_button.closest('.dumb-tabs').find('[data-dumb-tabs-tab-selector]').filter('.dumb-tabs__button_active');
        tab_toggle(active_button);// изменяем стили активной кнопки/контента
        tab_toggle(use_button);// изменяем стили выбранной кнопки/контента
        function tab_toggle (elem) {
            $(elem.data('dumb-tabs-tab-selector')).toggle();
            elem.toggleClass('dumb-tabs__button_active');
        }
    })
});
